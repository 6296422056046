<template>
    <a-card>
      <h3>导出档口商户信息</h3>
      <div style="margin-top: 24px">
        <a-row :gutter="0" style="line-height: 3">
          <div class="table-operator">
            <a-form layout="inline">
              <a-row :gutter="5" style="line-height: 3.5">
                <a-col :lg="8" :md="12" :sm="24">
                  <a-form-item label="选择城市:">
                    <a-select
                      mode="multiple"
                      style="width: 200px; margin-left: 0.5em"
                      :disabled="citySelectAll"
                      v-model="cityIds"
                      placeholder="请选择"
                    >
                      <a-select-option
                        v-for="item in userCities"
                        :key="item.id"
                        >{{ item.name }}</a-select-option
                      >
                    </a-select>
                    <a-checkbox v-model="citySelectAll" class="checkbox-margin"
                      >全选</a-checkbox
                    >
                  </a-form-item>
                </a-col>

                <a-col :lg="8" :md="12" :sm="24">
                  <a-form-item label="选择门店状态:">
                    <a-select
                      style="width: 200px; margin-left: 0.5em"
                      v-model="facilityStatus"
                      placeholder="请选择"
                    >
                      <a-select-option
                        v-for="item in facilityStatusOpts"
                        :key="item.value"
                        >{{ item.text }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>

                <a-col :lg="8" :md="12" :sm="24">
                  <a-form-item label="选择门店:">
                    <a-select
                      show-search
                      optionFilterProp="children"
                      style="width: 200px; margin-left: 0.5em"
                      v-model="facilityId"
                      placeholder="请选择"
                    >
                      <a-select-option
                        v-for="item in facilities"
                        :key="item.id"
                        >[{{ item.code }}]{{ item.optionText }}</a-select-option
                      >
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </a-row>
        <a-row :gutter="0" style="line-height: 3">
          <a-col :md="24" :sm="24" class="fr">
            <a-button
              style="margin-left: 1.5em"
              type="primary"
              :loading="kitchenInfoExporting"
              @click="exportKitchenInfoExcel"
              >导出</a-button
            >
          </a-col>
        </a-row>
      </div>
    </a-card>
</template>
<script>
import moment from "moment";
import fileDownload from "js-file-download";
import { mapActions, mapGetters } from "vuex";

import { GET_USER_CITIES } from "@/store/modules/actions.type";
// 1:承租合同已签约,2:施工中,3:已开业,4:停业中,5:已关店
const facilityStatusOpts = [
  { value: 0, text: "所有" },
  { value: 1, text: "承租合同已签约" },
  { value: 2, text: "施工中" },
  { value: 3, text: "已开业" },
  { value: 4, text: "停业中" },
  { value: 5, text: "已关店" },
];
export default {
  name: "Export",
  data() {
    return {
      // 表格导出1
      cityIds: [],
      facilityId: null,
      facilityStatus: null,
      kitchenInfoExporting: false,
      facilities: [],
      facilityStatusOpts,
      citySelectAll: true,
    };
  },
  methods: {
    ...mapActions("cities", [GET_USER_CITIES]),
    moment,

    getFacilitiesList() {
      axios({
        url: `${this.$baseUrl}/facility/queryFacilitySelectByIds`,
        method: "GET",
      }).then((res) => {
        if (res.data.success === true) {
          this.facilities = res?.data?.rows || [];
        }
      });
    },

    // 点击下载
    exportKitchenInfoExcel() {
      const params = {};
      if (this.cityIds.length > 0 || this.citySelectAll) {
        params.cityIds = this.citySelectAll ? [0] : this.cityIds;
      } else {
        this.$message.error("请选择城市！");
        return;
      }

      if (this.facilityId !== null) {
        params.facilityId = this.facilityId;
      }

      if (this.facilityStatus !== null) {
        params.facilityStatus = this.facilityStatus;
      }
      this.downloadTemplate(
        params,
        "coreBusinessMetric/exportCoreBusinessMetricBaseInfo",
        "kitchen-info"
      );
      setTimeout(() => {
        this.kitchenInfoExporting = false;
      }, 1000);
    },

    // 导出函数
    downloadTemplate(data, url, name) {
      this.kitchenInfoExporting = true;
      this.$http.post(url, data, {responseType: "blob"}).then((res) => {
        if (res.status == 200) {
          this.$message.success("下载成功！");
          const fileName = name + moment().format('YYYYMMDD') + ".xls";
          fileDownload(res.data, fileName);
        } else {
          this.$message.error("下载失败！");
        }
      });
    },
  },
  created() {
    this.getUserCities();
    this.getFacilitiesList();
  },
  computed: {
    ...mapGetters("cities", ["userCities"]),
  },
};
</script>
<style lang="less">
.fr {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.checkbox-margin {
  margin-left: 6px;
}
</style>
